@import 'src/assets/styles/mixins.scss';

.menu {
  height: 72vh;
  margin: 10px 0 10px 0;
  @media screen and (min-width: 1930px) {
    height: auto;
  }
  @media screen and (max-width: 770px) {
    position: absolute;
    left: 0;
    margin: 0;
    z-index: 99;
    border-radius: 5px;
    background-color: $white;
  }
  @media screen and (max-width: 430px) {
    height: auto;
    width: 100%;
  }
}

.main__menu {
  width: 380px;
  height: calc(100% - 55px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0 10px 0;
  border-radius: 0 5px 5px 0;
  @media screen and (max-width: 430px) {
    width: 100% !important;
  }
}

.short__menu {
  width: 380px;
  height: calc(100% - 145px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px 0 10px 0;
  border-radius: 0 5px 5px 0;
  @media screen and (max-width: 430px) {
    width: 100% !important;
  }
}

.maintopic {
  margin: 3px 0 0 0;
}

.maintopic__wrapper {
  border-bottom: 1px dashed $gray;

  div {
    height: auto !important;
    white-space: normal !important;
  }
  li {
    height: auto !important;
  }
}

.maintopic__sub {
  margin: 8px 0 8px 0;
  font-size: rem(17);
  white-space: normal;
}

.maintopic_admin_wrapper {
  border-bottom: 3px solid $gray;
}

.class_admin_wrapper {
  border-bottom: none;
}

.topic_admin_wrapper {
  margin: 6px 0 0 0;
}

.maintopic__button {
  padding: 0;
  margin-bottom: 10px;
  height: 35px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid $gray;
  color: gray;
}

.class__button {
  padding: 0;
  margin: -10px 4px 0 2px;
  height: 30px;
  width: 60%;
  border: 2px solid $gray;
  font-size: 17px;

  h5 {
    color: gray;
  }
}

.topic__button {
  padding: 0;
  margin: -10px 20px 5px 2px;
  height: 30px;
  width: 40%;
  border: 2px solid $gray;
  font-size: 17px;

  h5 {
    color: gray;
  }
}

.sub__title {
  padding: 12px 0 12px 0;
  margin: 0;
  min-width: 250px;
  text-align: center;
  border-bottom: 1px dashed $gray;
  font-weight: 600;
}

.submenu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
  border-bottom: 1px dashed $gray;

  h3 {
    margin: 0;
  }
}

.lessons {
  border-bottom: 1px dashed $gray;

  div {
    height: auto !important;
  }
}

.exercise__wrapper {
  height: auto !important;
}

.bottom__progress {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px 0 10px;
  width: 100%;
  height: 40px;

  .progress__text {
    text-align: center;
  }

  .progress__line {
    width: 100%;
  }
}

.reload__button {
  padding: 0;
  margin: 5px 10px 0 0;
  height: auto;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.reload__subbutton {
  padding: 0;
  margin: 10px 0 0 0;
  height: auto;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.back__button {
  width: 100%;
}

.hover__image {
  position: absolute;
  width: calc(100vw - 400px);
  height: 72vh;
  left: 385px;
  top: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $white;
  z-index: 1;
  @media screen and (min-width: 1930px) {
    height: 85vh;
  }
  @media screen and (max-width: 1450px) {
    width: calc(100vw - 390px);
  }
  @media screen and (max-width: 780px) {
    display: none;
  }
}

.menu__control {
  @media screen and (max-width: 770px) {
    position: absolute;
    top: 60px;
    left: 10px;
    margin: 0;
    background-color: #0190fe;
  }
}

.subtopic__menu__wrapper {
  white-space: normal;
  padding: 2px 5px;
}

.lesson__menu__wrapper {
  display: flex;
  justify-content: space-between;
  height: 100px;
  white-space: normal;

  .h2 {
    padding: 2px 5px;
  }
}
