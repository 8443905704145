@import 'src/assets/styles/mixins.scss';

.utils__content {
  margin: 10px;
  min-height: 72vh;
  width: 100%;
  background-color: transparent;
  @media screen and (min-width: 1930px) {
    min-height: none;
    height: auto;
  }
  @media screen and (max-width: 430px) {
    min-height: none;
    height: auto;
  }
}
