@import 'assets/styles/mixins.scss';

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 rem(20) 0 rem(50);
  min-height: 64px;
  height: 170px;
  border-bottom: 1px solid $gray-border;
  color: $text;
  @media screen and (max-width: 770px) {
    padding: 0;
  }
  .footer__column {
    width: 19%;
    .column__image {
      height: 90px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
    h3 {
      color: $text;
      @media screen and (max-width: 770px) {
        font-size: rem(15);
        height: 25px;
      }
    }
    p {
      margin: 0;
      height: 90px;
      word-wrap: break-word;
    }
  }
}
