@import 'assets/styles/mixins.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: $white;
  background-size: cover;
}

.footer {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 780px) {
    display: none;
  }
  p {
    margin: 13px 40px;
    color: $text-darken-more;
  }
  a {
    margin: 13px 20px;
    text-decoration: none;
    color: $text-darken-more;
  }
}

.footer__mobile {
  display: none;
  justify-content: center;
  @media screen and (max-width: 780px) {
    display: block;
    margin: 0 auto;
  }
  p {
    margin: 13px 0;
    color: $text-darken-more;
    text-align: center;
  }
  a {
    margin: 13px 20px;
    text-decoration: none;
    color: $text-darken-more;
  }
}
